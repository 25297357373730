import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import MarkdownToHtml from 'junior/components/markdown-to-html';
export default class Bubble extends Component {
    get getClasses() {
        let className1 = 'bubble';
        if (this.args.status) {
            className1 += ` bubble--${this.args.status}`;
        }
        return className1;
    }
    static{
        template(`<MarkdownToHtml ...attributes @markdown={{@message}} @class={{this.getClasses}} />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
