import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import * as markdownConverter from 'junior/utils/markdown-converter';
export default class RobotDialog extends Component {
    @action
    readTheInstruction(text1) {
        if (!window.speechSynthesis.speaking) {
            const utterance1 = new SpeechSynthesisUtterance();
            const parser1 = new DOMParser();
            const parsedText1 = parser1.parseFromString(markdownConverter.toHTML(text1), 'text/html').body.innerText;
            utterance1.text = parsedText1;
            utterance1.lang = 'fr-FR';
            window.speechSynthesis.speak(utterance1);
        }
    }
    get getRobotImageUrl() {
        return `/images/robot/dialog-robot-${this.args.class ? this.args.class : 'default'}.svg`;
    }
    static{
        template(`
    <div class="robot-speaking">
      <img class="robot-speaking__logo" alt="mascotte pix1d" src={{this.getRobotImageUrl}} />
      <div class="bubbles">
        {{yield}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
} // {{!--  {{!  À activer quand le design + fonctionnalité sont actés }}--}}
 // {{!--  {{!  <PixIconButton}}--}}
 // {{!--  {{!    @ariaLabel="Lire la consigne à haute voix"}}--}}
 // {{!--  {{!    @withBackground="true"}}--}}
 // {{!--  {{!    @icon="volume-high"}}--}}
 // {{!--  {{!--    @triggerAction={{fn this.readTheInstruction @instruction}}--}}
 //   {{!--  {{!  />}}--}}
 //     {{!</div>}}
